<template>
  <form @keyup.enter="saveData">
    <b-field
      disabled
      label="Denominação Social"
      :type="campoInvalido('socialDenomination', 'data') ? 'is-danger' : ''"
      :message="
        campoInvalido('socialDenomination', 'data')
          ? 'Denominação Social é um campo obrigatório'
          : ''
      "
    >
      <b-input
        custom-class="uppercase"
        placeholder="Denominação Social"
        :disabled="disableFields"
        v-model="$v.data.socialDenomination.$model"
        @blur="event => converterMaiusculo('socialDenomination', event.target.value, 'data')"
      ></b-input>
    </b-field>

    <b-field
      disabled
      label="Nome Fantasia"
      :type="campoInvalido('fantasyName', 'data') ? 'is-danger' : ''"
      :message="campoInvalido('fantasyName', 'data') ? 'Nome Fantasia é um campo obrigatório' : ''"
    >
      <b-input
        custom-class="uppercase"
        placeholder="Nome Fantasia"
        :disabled="disableFields"
        v-model="$v.data.fantasyName.$model"
        @blur="event => converterMaiusculo('fantasyName', event.target.value, 'data')"
      ></b-input>
    </b-field>

    <b-field grouped class="column-direction-touch">
      <b-field
        expanded
        label="Natureza Jurídica"
        :type="campoInvalido('legalNature', 'data') ? 'is-danger' : ''"
        :message="
          campoInvalido('legalNature', 'data') ? 'Natureza Jurídica é um campo obrigatório' : ''
        "
      >
        <b-select
          expanded
          placeholder="Natureza Jurídica"
          :disabled="disableFields"
          v-model="$v.data.legalNature.$model"
          @click.native="$v.data.legalNature.$touch()"
        >
          <option v-for="option in legalNatureOptions" :key="option.id" :value="option.id">
            {{ option.name }}
          </option>
        </b-select>
      </b-field>

      <b-field
        expanded
        label="CNPJ"
        :type="campoInvalido('cnpj', 'data') ? 'is-danger' : ''"
        :message="
          campoInvalido('cnpj', 'data')
            ? 'CNPJ é um campo obrigatório. Insira um número válido'
            : ''
        "
      >
        <b-input
          placeholder="CNPJ"
          :disabled="disableFields"
          maxlength="18"
          :has-counter="false"
          v-mascara:cnpj
          v-model="$v.data.cnpj.$model"
          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
        ></b-input>
      </b-field>
    </b-field>

    <hr />

    <b-field grouped class="column-direction-touch">
      <b-field
        label="CEP"
        :type="campoInvalido('zipCode', 'data') ? 'is-danger' : ''"
        :message="campoInvalido('zipCode', 'data') ? 'CEP é um campo obrigatório' : ''"
      >
        <b-input
          placeholder="CEP"
          maxlength="9"
          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
          :disabled="disableFields"
          :has-counter="false"
          :loading="searching"
          @blur="searchCEP"
          @keyup.enter.prevent="searchCEP"
          v-mascara:cep
          v-model.trim="$v.data.zipCode.$model"
        />
      </b-field>

      <b-field
        expanded
        label="Endereço"
        :type="campoInvalido('address', 'data') ? 'is-danger' : ''"
        :message="campoInvalido('address', 'data') ? 'Endereço é um campo obrigatório' : ''"
      >
        <b-input
          placeholder="Endereço"
          :disabled="disableFields"
          :loading="searching"
          v-model="$v.data.address.$model"
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="column-direction-touch">
      <b-field
        label="Número"
        :type="campoInvalido('number', 'data') ? 'is-danger' : ''"
        :message="campoInvalido('number', 'data') ? 'Número é um campo obrigatório' : ''"
      >
        <b-input placeholder="Número" :disabled="disableFields" v-model="$v.data.number.$model" />
      </b-field>

      <b-field expanded label="Complemento">
        <b-input
          placeholder="Complemento"
          :disabled="disableFields"
          v-model="$v.data.complement.$model"
        />
      </b-field>
    </b-field>

    <b-field grouped class="column-direction-touch">
      <b-field
        expanded
        label="Bairro"
        :type="campoInvalido('neighborhood', 'data') ? 'is-danger' : ''"
        :message="campoInvalido('neighborhood', 'data') ? 'Bairro é um campo obrigatório' : ''"
      >
        <b-input
          placeholder="Bairro"
          v-model="$v.data.neighborhood.$model"
          :disabled="disableFields"
          :loading="searching"
        />
      </b-field>

      <b-field
        expanded
        label="Município"
        :type="campoInvalido('city', 'data') ? 'is-danger' : ''"
        :message="campoInvalido('city', 'data') ? 'Município é um campo obrigatório' : ''"
      >
        <b-input
          placeholder="Município"
          v-model="$v.data.city.$model"
          :disabled="disableFields"
          :loading="searching"
        />
      </b-field>

      <b-field
        expanded
        label="Código IBGE"
        :type="campoInvalido('IBGECode', 'data') ? 'is-danger' : ''"
        :message="campoInvalido('IBGECode', 'data') ? 'Código IBGE é um campo obrigatório' : ''"
      >
        <b-input
          placeholder="Código IBGE"
          maxlength="7"
          v-model="$v.data.IBGECode.$model"
          :disabled="disableFields"
          :has-counter="false"
        />
      </b-field>

      <b-field
        expanded
        label="UF"
        :type="campoInvalido('state', 'data') ? 'is-danger' : ''"
        :message="campoInvalido('state', 'data') ? 'UF é um campo obrigatório' : ''"
      >
        <b-select
          expanded
          placeholder="UF"
          v-model="$v.data.state.$model"
          :disabled="disableFields"
          :loading="searching"
          @click.native="$v.data.state.$touch()"
        >
          <option v-for="option in ufOptions" :key="option.id" :value="option.value">
            {{ option.name }}
          </option>
        </b-select>
      </b-field>
    </b-field>

    <hr />

    <b-field grouped class="column-direction-touch">
      <b-field
        label="Telefone"
        :type="campoInvalido('telephone', 'data') ? 'is-danger' : ''"
        :message="campoInvalido('telephone', 'data') ? 'Telefone é um campo obrigatório' : ''"
      >
        <b-input
          maxlength="12"
          placeholder="Telefone"
          v-mascara:telefone
          v-model="$v.data.telephone.$model"
          :disabled="disableFields"
          :has-counter="false"
          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
        />
      </b-field>

      <b-field label="Celular">
        <b-input
          maxlength="13"
          placeholder="Celular"
          v-mascara:telefone
          v-model="$v.data.cellphone.$model"
          :disabled="disableFields"
          :has-counter="false"
          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
        />
      </b-field>

      <b-field
        expanded
        label="E-mail"
        :type="campoInvalido('email', 'data') ? 'is-danger' : ''"
        :message="campoInvalido('email', 'data') ? 'Insira um e-mail válido' : ''"
      >
        <b-input
          v-model="$v.data.email.$model"
          placeholder="E-mail"
          :disabled="disableFields"
          @input="data.email = data.email.toLowerCase()"
        ></b-input>
      </b-field>
    </b-field>

    <b-field>
      <b-button
        class="button is-primary is-fullwidth save-button"
        :loading="loading"
        :disabled="disableFields"
        @click.prevent.stop="saveData()"
        >Salvar</b-button
      >
    </b-field>
  </form>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { email, required } from 'vuelidate/lib/validators';
import { isValidCnpj } from '@brazilian-utils/validators';

import fieldMixin from '@/mixins/formulario';
import { dadosCEP as fetchCEP } from '@/resources/utils';
import { ufOptions, legalNatureOptions } from '@/utils/data';

export default {
  name: 'BuyerData',
  mixins: [fieldMixin],
  data() {
    const data = {
      address: '',
      cellphone: '',
      city: '',
      cnpj: '',
      complement: '',
      email: '',
      fantasyName: '',
      legalNature: '',
      IBGECode: null,
      neighborhood: '',
      number: '',
      state: '',
      socialDenomination: '',
      telephone: '',
      zipCode: '',
    };

    return {
      data,
      confirmed: false,
      legalNatureOptions,
      loading: false,
      searching: false,
      ufOptions,
    };
  },
  async beforeRouteLeave(to, from, next) {
    if (this.logged && this.$v.data.$anyDirty) {
      const exit = await this.$confirmacao({
        message: 'Os dados serão perdidos. Tem certeza que deseja continuar?',
      });
      return next(exit);
    }
    return next();
  },
  watch: {
    prefecture(prefecture) {
      if (prefecture) {
        this.data.address = prefecture.address;
        this.data.cellphone = prefecture.cellphone;
        this.data.city = prefecture.city;
        this.data.complement = prefecture.complement;
        this.data.cnpj = prefecture.cnpj;
        this.data.email = prefecture.email;
        this.data.fantasyName = prefecture.fantasyName;
        this.data.legalNature = prefecture.legalNature;
        this.data.IBGECode = prefecture.IBGECode;
        this.data.neighborhood = prefecture.neighborhood;
        this.data.number = prefecture.number;
        this.data.socialDenomination = prefecture.socialDenomination;
        this.data.state = prefecture.state;
        this.data.telephone = prefecture.telephone;
        this.data.zipCode = prefecture.zipCode;
      }
    },
  },
  computed: {
    ...mapState('prefectures', ['prefecture']),
    ...mapGetters({ user: 'getUser', logged: 'getLogged' }),
    disableFields() {
      return (
        !this.logged ||
        !this.user.empresas.some(
          prefectureUser =>
            prefectureUser.id === this.prefecture.id && prefectureUser.editar_empresa,
        )
      );
    },
  },
  validations: {
    data: {
      address: { required },
      cellphone: { required: false },
      city: { required },
      cnpj: { isValidCnpj },
      complement: { required: false },
      email: { email, required },
      fantasyName: { required },
      legalNature: { required },
      IBGECode: { required },
      neighborhood: { required },
      number: { required },
      socialDenomination: { required },
      state: { required },
      telephone: { required },
      zipCode: { required },
    },
  },
  methods: {
    ...mapActions('prefectures', ['savePrefectureData']),
    async saveData() {
      if (this.validar('data')) {
        this.loading = true;
        try {
          const data = { ...this.data };
          data.seller = false;
          data.buyer = true;
          data.status = 'HABILITADA';
          await this.savePrefectureData(data);
          this.$alerta('Dados salvos', 'is-success');
          this.confirmed = true;
          this.$v.data.$reset();
        } catch (error) {
          this.$alerta(error.message, 'is-danger');
        }
        this.loading = false;
      }
    },
    async searchCEP() {
      if (this.data.zipCode) {
        this.searching = true;
        try {
          const {
            data: { data: response },
          } = await fetchCEP(this.data.zipCode);
          this.data.address = response.address;
          this.data.city = response.city;
          this.data.IBGECode = response.cityIBGE;
          this.data.neighborhood = response.neighborhood;
          this.data.state = response.state;
        } catch (error) {
          this.$alerta(error.response.data.error, 'is-danger', 3000);
        }
        this.searching = false;
      }
    },
  },
};
</script>
