<template>
  <div>
    <Buyer v-if="company.buyer" ref="buyer" />
    <Seller v-else ref="seller" />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import Buyer from './Buyer/BuyerData';
import Seller from './Seller/SellerData';

export default {
  name: 'CompanyData',
  components: {
    Buyer,
    Seller,
  },
  computed: {
    ...mapState('companies', ['company']),
    ...mapGetters({
      logged: 'getLogged',
    }),
  },
  async beforeRouteLeave(to, from, next) {
    if (
      (this.logged && this.$refs.seller && this.$refs.seller.$v.data.$anyDirty) ||
      (this.logged && this.$refs.buyer && this.$refs.buyer.$v.data.$anyDirty)
    ) {
      const sair = await this.$confirmacao({
        message: 'Os dados serão perdidos. Tem certeza que deseja continuar?',
      });
      return next(sair);
    }
    return next();
  },
};
</script>
