<template>
  <form @keyup.enter="saveData">
    <b-field
      label="Denominação Social"
      :type="campoInvalido('socialDenomination', 'data') ? 'is-danger' : ''"
      :message="
        campoInvalido('socialDenomination', 'data')
          ? 'Denominação Social é um campo obrigatório'
          : ''
      "
    >
      <b-input
        custom-class="uppercase"
        placeholder="Denominação Social"
        :disabled="disableFields"
        v-model="$v.data.socialDenomination.$model"
        @blur="event => converterMaiusculo('socialDenomination', event.target.value, 'data')"
      ></b-input>
    </b-field>

    <b-field
      label="Nome Fantasia"
      :type="campoInvalido('fantasyName', 'data') ? 'is-danger' : ''"
      :message="campoInvalido('fantasyName', 'data') ? 'Nome Fantasia é um campo obrigatório' : ''"
    >
      <b-input
        custom-class="uppercase"
        placeholder="Nome Fantasia"
        :disabled="disableFields"
        v-model="$v.data.fantasyName.$model"
        @blur="event => converterMaiusculo('fantasyName', 'data', event.target.value)"
      ></b-input>
    </b-field>

    <b-field grouped class="column-direction-touch">
      <b-field
        expanded
        label="Natureza Jurídica"
        :type="campoInvalido('legalNature', 'data') ? 'is-danger' : ''"
        :message="
          campoInvalido('legalNature', 'data') ? 'Natureza Jurídica é um campo obrigatório' : ''
        "
      >
        <b-select
          expanded
          :disabled="disableFields"
          placeholder="Natureza Jurídica"
          v-model="$v.data.legalNature.$model"
          @click.native="$v.data.legalNature.$touch()"
        >
          <option v-for="option in legalNatureOptions" :key="option.id" :value="option.id">
            {{ option.name }}
          </option>
        </b-select>
      </b-field>

      <b-field
        expanded
        label="Atividades"
        :type="campoInvalido('activity', 'data') ? 'is-danger' : ''"
        :message="campoInvalido('activity', 'data') ? 'Atividades é um campo obrigatório' : ''"
      >
        <b-select
          expanded
          placeholder="Atividades"
          :disabled="disableFields"
          v-model="$v.data.activity.$model"
          @click.native="$v.data.activity.$touch()"
        >
          <option v-for="option in activitiesOptions" :key="option.id" :value="option.id">
            {{ option.name }}
          </option>
        </b-select>
      </b-field>
    </b-field>

    <b-field grouped class="column-direction-touch">
      <b-field
        expanded
        :type="campoInvalido('shareCapital', 'data') ? 'is-danger' : ''"
        :message="campoInvalido('shareCapital', 'data') ? 'Adicione um valor válido' : ''"
      >
        <template #label>
          Capital Social
          <b-tooltip
            label="Exemplo: R$ 1.000.000,00 (1 milhão)"
            :position="mobile ? 'is-top' : 'is-right'"
            type="is-primary"
          >
            <b-icon size="is-small" icon="help-circle-outline"></b-icon>
          </b-tooltip>
        </template>
        <b-input
          placeholder="Capital Social"
          v-mascara:valor
          v-model="maskValues.shareCapital"
          :disabled="disableFields"
          @blur="maskValues.shareCapital = formatarValor(data.shareCapital, 2)"
          @input.native="event => atualizarValor('shareCapital', event, 'data', 'maskValues')"
          @keypress.native="teclasPermitidasValor($event)"
        >
        </b-input>
      </b-field>

      <b-field
        expanded
        label="Data Capital Social"
        :type="campoInvalido('shareCapitalDate', 'data') ? 'is-danger' : ''"
        :message="
          campoInvalido('shareCapitalDate', 'data')
            ? 'Data Capital Social é um campo obrigatório'
            : ''
        "
      >
        <b-datepicker
          editable
          placeholder="Data Capital Social"
          icon="calendar-today"
          :disabled="disableFields"
          v-mascara:data
          v-model="maskValues.shareCapitalDate"
          @input="valor => atualizarData('shareCapitalDate', valor, 'data', 'maskValues')"
          @keypress.native="teclasPermitidasNumero($event)"
        >
          <button
            class="button is-primary br-4 mr-1"
            @click.prevent.stop="inserirHoje('shareCapitalDate', 'data', 'maskValues')"
          >
            <b-icon icon="calendar-today" />
            <span>Hoje</span>
          </button>

          <button
            class="button is-danger br-4"
            @click.prevent.stop="limparData('shareCapitalDate', 'data', 'maskValues')"
          >
            <b-icon icon="close" />
            <span>Limpar</span>
          </button>
        </b-datepicker>
      </b-field>
    </b-field>

    <b-field grouped class="column-direction-touch">
      <b-field
        expanded
        label="CNPJ"
        :type="campoInvalido('cnpj', 'data') ? 'is-danger' : ''"
        :message="
          campoInvalido('cnpj', 'data')
            ? 'CNPJ é um campo obrigatório. Insira um número válido'
            : ''
        "
      >
        <b-input
          placeholder="CNPJ"
          maxlength="18"
          :has-counter="false"
          :disabled="disableFields"
          v-mascara:cnpj
          v-model="$v.data.cnpj.$model"
          @keypress.native="teclasPermitidasNumero($event)"
        />
      </b-field>

      <b-field expanded label="Inscrição Estadual">
        <b-input
          maxlength="15"
          placeholder="Inscrição Estadual"
          :disabled="disableFields"
          :has-counter="false"
          v-mascara:inscricaoEstadual
          v-model="data.stateRegistration"
          @keypress.native="teclasPermitidasNumero($event)"
        />
      </b-field>

      <b-field
        expanded
        label="Inscrição Municipal"
        :type="campoInvalido('municipalRegistration', 'data') ? 'is-danger' : ''"
        :message="
          campoInvalido('municipalRegistration', 'data')
            ? 'Inscrição Municipal é um campo obrigatório'
            : ''
        "
      >
        <b-input
          placeholder="Inscrição Municipal"
          :disabled="disableFields"
          v-model="$v.data.municipalRegistration.$model"
          @keypress.native="teclasPermitidasNumero($event)"
        />
      </b-field>
    </b-field>

    <hr />

    <b-field grouped class="column-direction-touch">
      <b-field
        label="CEP"
        :type="campoInvalido('zipCode', 'data') ? 'is-danger' : ''"
        :message="campoInvalido('zipCode', 'data') ? 'CEP é um campo obrigatório' : ''"
      >
        <b-input
          placeholder="CEP"
          maxlength="9"
          @keypress.native="teclasPermitidasNumero($event)"
          :disabled="disableFields"
          :has-counter="false"
          :loading="searching"
          @blur="fetchCEP"
          @keyup.native.enter.prevent.stop="fetchCEP"
          v-mascara:cep
          v-model.trim="$v.data.zipCode.$model"
        />
      </b-field>

      <b-field
        expanded
        label="Endereço"
        :type="campoInvalido('address', 'data') ? 'is-danger' : ''"
        :message="campoInvalido('address', 'data') ? 'Endereço é um campo obrigatório' : ''"
      >
        <b-input
          placeholder="Endereço"
          :disabled="disableFields"
          :loading="searching"
          v-model="$v.data.address.$model"
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="column-direction-touch">
      <b-field
        label="Número"
        :type="campoInvalido('number', 'data') ? 'is-danger' : ''"
        :message="campoInvalido('number', 'data') ? 'Número é um campo obrigatório' : ''"
      >
        <b-input placeholder="Número" :disabled="disableFields" v-model="$v.data.number.$model" />
      </b-field>

      <b-field expanded label="Complemento">
        <b-input
          placeholder="Complemento"
          :disabled="disableFields"
          v-model="$v.data.complement.$model"
        />
      </b-field>
    </b-field>

    <b-field grouped class="column-direction-touch">
      <b-field
        expanded
        label="Bairro"
        :type="campoInvalido('neighborhood', 'data') ? 'is-danger' : ''"
        :message="campoInvalido('neighborhood', 'data') ? 'Bairro é um campo obrigatório' : ''"
      >
        <b-input
          placeholder="Bairro"
          :disabled="disableFields"
          :loading="searching"
          v-model="$v.data.neighborhood.$model"
        />
      </b-field>

      <b-field
        expanded
        label="Município"
        :type="campoInvalido('city', 'data') ? 'is-danger' : ''"
        :message="campoInvalido('city', 'data') ? 'Município é um campo obrigatório' : ''"
      >
        <b-input
          placeholder="Município"
          :disabled="disableFields"
          :loading="searching"
          v-model="$v.data.city.$model"
        />
      </b-field>

      <b-field
        expanded
        label="Código IBGE"
        :type="campoInvalido('IBGECode', 'data') ? 'is-danger' : ''"
        :message="campoInvalido('IBGECode', 'data') ? 'Código IBGE é um campo obrigatório' : ''"
      >
        <b-input
          maxlength="7"
          placeholder="Código IBGE"
          :has-counter="false"
          :disabled="disableFields"
          v-model="$v.data.IBGECode.$model"
          @keypress.native="teclasPermitidasNumero($event)"
        />
      </b-field>

      <b-field
        expanded
        label="UF"
        :type="campoInvalido('state', 'data') ? 'is-danger' : ''"
        :message="campoInvalido('state', 'data') ? 'UF é um campo obrigatório' : ''"
      >
        <b-select
          expanded
          placeholder="UF"
          :disabled="disableFields"
          :loading="searching"
          v-model="$v.data.state.$model"
          @click.native="$v.data.state.$touch()"
        >
          <option v-for="option in ufOptions" :key="option.id" :value="option.value">
            {{ option.name }}
          </option>
        </b-select>
      </b-field>
    </b-field>

    <b-field grouped class="column-direction-touch">
      <b-field
        label="Telefone"
        :type="campoInvalido('telephone', 'data') ? 'is-danger' : ''"
        :message="campoInvalido('telephone', 'data') ? 'Telefone é um campo obrigatório' : ''"
      >
        <b-input
          placeholder="Telefone"
          :disabled="disableFields"
          maxlength="12"
          :has-counter="false"
          v-mascara:telefone
          v-model="$v.data.telephone.$model"
          @keypress.native="teclasPermitidasNumero($event)"
        />
      </b-field>

      <b-field label="Celular">
        <b-input
          placeholder="Celular"
          :disabled="disableFields"
          maxlength="13"
          :has-counter="false"
          v-mascara:telefone
          v-model="$v.data.cellphone.$model"
          @keypress.native="teclasPermitidasNumero($event)"
        />
      </b-field>

      <b-field
        expanded
        label="E-mail"
        :type="campoInvalido('email', 'data') ? 'is-danger' : ''"
        :message="campoInvalido('email', 'data') ? 'Insira um e-mail válido' : ''"
      >
        <b-input
          placeholder="E-mail"
          :disabled="disableFields"
          v-model="$v.data.email.$model"
          @input="data.email = data.email.toLowerCase()"
        />
      </b-field>
    </b-field>

    <hr />

    <b-field
      expanded
      label="Responsável Cobrança"
      :type="campoInvalido('billingResponsible', 'data') ? 'is-danger' : ''"
      :message="
        campoInvalido('billingResponsible', 'data')
          ? 'Responsável cobrança é um campo obrigatório'
          : ''
      "
    >
      <b-input
        expanded
        placeholder="Responsável Cobrança"
        v-model="$v.data.billingResponsible.$model"
      ></b-input>
    </b-field>

    <b-field grouped class="column-direction-touch">
      <b-field
        label="Telefone Cobrança"
        :type="campoInvalido('billingTelephone', 'data') ? 'is-danger' : ''"
        :message="
          campoInvalido('billingTelephone', 'data')
            ? 'Telefone cobrança é um campo obrigatório'
            : ''
        "
      >
        <b-input
          placeholder="Telefone Cobrança"
          maxlength="12"
          :has-counter="false"
          v-mascara:telefone
          v-model="$v.data.billingTelephone.$model"
          @keypress.native="teclasPermitidasNumero($event)"
        />
      </b-field>

      <b-field label="Celular Cobrança">
        <b-input
          placeholder="Celular Cobrança"
          maxlength="13"
          :has-counter="false"
          v-mascara:telefone
          v-model="$v.data.billingCellphone.$model"
          @keypress.native="teclasPermitidasNumero($event)"
        />
      </b-field>

      <b-field
        expanded
        label="E-mail Cobrança"
        :type="campoInvalido('billingEmail', 'data') ? 'is-danger' : ''"
        :message="
          campoInvalido('billingEmail', 'data') ? 'E-mail cobrança é um campo obrigatório' : ''
        "
      >
        <b-input
          expanded
          placeholder="E-mail"
          v-model="$v.data.billingEmail.$model"
          @input="data.billingEmail = data.billingEmail.toLowerCase()"
        />
      </b-field>
    </b-field>

    <button
      class="button is-primary is-fullwidth save-button"
      :class="{ ' is-loading': loading }"
      :disabled="disableFields"
      @click.prevent.stop="saveData"
    >
      Salvar
    </button>
  </form>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { email, required, minValue, maxValue } from 'vuelidate/lib/validators';
import { isValidCnpj } from '@brazilian-utils/validators';

import fieldMixin from '@/mixins/formulario';
import { dadosCEP as fetchDataCEP } from '@/resources/utils';
import { formatarValor } from '@/utils/format';
import { ufOptions, legalNatureOptions, activitiesOptions } from '@/utils/data';

export default {
  name: 'SellerData',
  mixins: [fieldMixin],
  data() {
    const data = {
      activity: '',
      address: '',
      billingCellphone: '',
      billingEmail: '',
      billingResponsible: '',
      billingTelephone: '',
      cellphone: '',
      city: '',
      cnpj: '',
      complement: '',
      email: '',
      fantasyName: '',
      legalNature: '',
      IBGECode: null,
      municipalRegistration: '',
      neighborhood: '',
      number: '',
      shareCapital: 0,
      shareCapitalDate: null,
      socialDenomination: '',
      state: '',
      stateRegistration: '',
      telephone: '',
      zipCode: '',
    };
    const maskValues = {
      shareCapital: null,
      shareCapitalDate: null,
    };

    return {
      activitiesOptions,
      confirmed: false,
      data,
      formatarValor,
      legalNatureOptions,
      loading: false,
      maskValues,
      searching: false,
      ufOptions,
    };
  },
  watch: {
    company(company) {
      if (company) {
        this.data.activity = company.activity;
        this.data.address = company.address;
        this.data.billingCellphone = this.company.billingCellphone;
        this.data.billingEmail = this.company.billingEmail;
        this.data.billingResponsible = this.company.billingResponsible;
        this.data.billingTelephone = this.company.billingTelephone;
        this.data.cellphone = company.cellphone;
        this.data.city = company.city;
        this.data.cnpj = company.cnpj;
        this.data.complement = company.complement;
        this.data.socialDenomination = company.socialDenomination;
        this.data.email = company.email;
        this.data.fantasyName = company.fantasyName;
        this.data.legalNature = company.legalNature;
        this.data.IBGECode = company.IBGECode;
        this.data.municipalRegistration = company.municipalRegistration;
        this.data.neighborhood = company.neighborhood;
        this.data.number = company.number;
        this.data.shareCapital = company.shareCapital;
        if (company.shareCapital) {
          this.maskValues.shareCapital = formatarValor(company.shareCapital, 2);
        }
        this.data.shareCapitalDate = company.shareCapitalDate;
        this.maskValues.shareCapitalDate = company.shareCapitalDate;
        this.data.state = company.state;
        this.data.stateRegistration = company.stateRegistration;
        this.data.telephone = company.telephone;
        this.data.zipCode = company.zipCode;
      }
    },
  },
  validations: {
    data: {
      activity: { required },
      address: { required },
      billingCellphone: { required: false },
      billingEmail: { required },
      billingResponsible: { required },
      billingTelephone: { required },
      cellphone: { required: false },
      city: { required },
      cnpj: { isValidCnpj },
      complement: { required: false },
      email: { email, required },
      fantasyName: { required },
      legalNature: { required },
      IBGECode: { required },
      municipalRegistration: { required },
      neighborhood: { required },
      number: { required },
      shareCapital: {
        required,
        minValue: minValue(0.0001),
        maxValue: maxValue(99999999999999.9999),
      },
      shareCapitalDate: { required },
      socialDenomination: { required },
      state: { required },
      telephone: { required },
      zipCode: { required },
    },
  },
  computed: {
    ...mapState('companies', ['company']),
    ...mapGetters({ user: 'getUser' }),
    ...mapGetters('app', { mobile: 'mobile' }),
    disableFields() {
      return !this.user.empresas.some(
        companyUser => companyUser.id === this.company.id && companyUser.editar_empresa,
      );
    },
  },
  methods: {
    ...mapActions('companies', ['saveCompany']),
    async fetchCEP() {
      if (this.data.zipCode) {
        this.searching = true;
        try {
          const {
            data: { data: response },
          } = await fetchDataCEP(this.data.zipCode);
          this.data.address = response.address;
          this.data.neighborhood = response.neighborhood;
          this.data.city = response.city;
          this.data.IBGECode = response.cityIBGE;
          this.data.state = response.state;
        } catch (error) {
          this.$alerta(error.response.data.error, 'is-danger', 3000);
        }
        this.searching = false;
      }
    },
    async saveData() {
      if (this.validar('data')) {
        this.loading = true;
        try {
          const data = { ...this.data };
          await this.saveCompany(data);
          this.$alerta('Dados salvos', 'is-success');
          this.confirmed = true;
          this.$v.data.$reset();
        } catch (error) {
          this.$alerta(error.message, 'is-danger');
        }
        this.loading = false;
      }
    },
  },
};
</script>
